export enum Collection {
  PLANO = 'n-planos',
  AULA = 'n-aulas',
  RESERVA = 'n-reservas',
  FATURA = 'n-faturas',
  ALUNO = 'n-alunos',
  PECA = 'n-pecas',
  AGENDAMENTO_AUTOMATICO = 'n-agendamento-automatico',
  CHAMADA = 'n-chamadas',
  FERIADO = 'feriados',
  CONFIGURACAO = 'configuracao',
  RELATORIO = 'n-relatorios',
  FATURA_MONTH_REPORT = 'n-month-report-fatura',
  FATURA_WEEK_REPORT = 'n-week-report-fatura',
}
