import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { FirebaseFunctions } from '../models/function-util';

export interface RequestConfig<T = any> {
  loadingMessage: string;
  successMessage?: string;
  showErrorToast?: boolean;
  onError?: () => void;
  onSuccess?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class FirebaseFunctionsService {
  constructor(
    private loadingController: LoadingController,
    private toastController: ToastController
  ) {}

  public callable<T = any, E = any>(
    functionName: string,
    config: RequestConfig<E>
  ): (payload: T) => Promise<E> {
    return async (payload: T): Promise<E> => {
      const loading = await this.loadingController.create({
        message: config.loadingMessage,
      });
      await loading.present();

      try {
        const response = await FirebaseFunctions.get<T, E>(functionName)(
          payload
        );
        await loading.dismiss();

        if (config.successMessage) {
          const toast = await this.toastController.create({
            message: config.successMessage,
            duration: 1500,
            color: 'light',
            position: 'top',
          });
          await toast.present();
        }

        return response.data;
      } catch (error: any) {
        if (
          config.showErrorToast === true ||
          config.showErrorToast === undefined
        ) {
          await loading.dismiss();
          const toast = await this.toastController.create({
            message: error.message,
            color: 'danger',
            duration: 1500,
          });
          await toast.present();
        }
        throw error;
      }
    };
  }

  public callableWithoutLoading<T = any, E = any>(
    functionName: string,
    config?: RequestConfig<E>
  ): (payload: T) => Promise<E> {
    return async (payload: T): Promise<E> => {
      try {
        const response = await FirebaseFunctions.get<T, E>(functionName)(
          payload
        );

        if (config?.onSuccess) {
          config.onSuccess();
        }

        if (config?.successMessage) {
          const toast = await this.toastController.create({
            message: config.successMessage,
            duration: 1500,
            color: 'primary',
          });
          await toast.present();
        }

        return response.data;
      } catch (error: any) {
        if (config?.onError) {
          config.onError();
        }

        if (
          config?.showErrorToast === true ||
          config?.showErrorToast === undefined
        ) {
          const toast = await this.toastController.create({
            message: error.message,
            color: 'danger',
            duration: 1500,
          });
          await toast.present();
        }
        throw error;
      }
    };
  }
}
