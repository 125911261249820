import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'agendamento',
    loadChildren: () =>
      import('./agenda/pages/agendamento/agendamento.module').then(
        (m) => m.AgendamentoPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/alunos/:adminAluno/agendamento/:adminAluno',
    loadChildren: () =>
      import('./agenda/pages/agendamento/agendamento.module').then(
        (m) => m.AgendamentoPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/alunos/novo',
    loadChildren: () =>
      import('./alunos/pages/admin-novo-aluno/admin-novo-aluno.module').then(
        (m) => m.AdminNovoAlunoPageModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'informacoes',
    loadChildren: () =>
      import('./login/pages/informacao/informacao.module').then(
        (m) => m.InformacaoPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/alunos/:adminAluno/informacoes/:adminAluno',
    loadChildren: () =>
      import('./login/pages/informacao/informacao.module').then(
        (m) => m.InformacaoPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'pecas/nova',
    loadChildren: () =>
      import(
        './pecas/pages/cadastro-peca-aluno/cadastro-peca-aluno.module'
      ).then((m) => m.CadastroPecaAlunoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/alunos/:adminAluno/pecas/:adminAluno/nova',
    loadChildren: () =>
      import(
        './pecas/pages/cadastro-peca-aluno/cadastro-peca-aluno.module'
      ).then((m) => m.CadastroPecaAlunoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/alunos/:adminAluno/pecas/:adminAluno/:id',
    loadChildren: () =>
      import(
        './pecas/pages/cadastro-peca-aluno/cadastro-peca-aluno.module'
      ).then((m) => m.CadastroPecaAlunoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./login/pages/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyPageModule
      ),
  },
  {
    path: 'terms-of-service',
    loadChildren: () =>
      import('./login/pages/terms-of-service/terms-of-service.module').then(
        (m) => m.TermsOfServicePageModule
      ),
  },
  {
    path: 'planos',
    loadChildren: () =>
      import('./planos/planos.module').then((m) => m.PlanosPageModule),
  },
  {
    path: 'planos/:id/contratacao',
    loadChildren: () =>
      import(
        './planos/pages/confirmar-contratacao/confirmar-contratacao.module'
      ).then((m) => m.ConfirmarContratacaoPageModule),
  },
  {
    path: 'admin/alunos/:adminAluno/planos/:adminAluno/:id/contratacao',
    loadChildren: () =>
      import(
        './planos/pages/confirmar-contratacao/confirmar-contratacao.module'
      ).then((m) => m.ConfirmarContratacaoPageModule),
  },
  {
    path: 'custos',
    loadChildren: () =>
      import('./custos/custos.module').then((m) => m.CustosPageModule),
  },
  {
    path: 'custos/abertos',
    loadChildren: () =>
      import(
        './custos/pages/detalhe-custos-abertos/detalhe-custos-abertos.module'
      ).then((m) => m.DetalheCustosAbertosPageModule),
  },
  {
    path: 'admin/alunos/:adminAluno/custos/:adminAluno/abertos',
    loadChildren: () =>
      import(
        './custos/pages/detalhe-custos-abertos/detalhe-custos-abertos.module'
      ).then((m) => m.DetalheCustosAbertosPageModule),
  },
  {
    path: 'admin/alunos/:adminAluno/faturas/:adminAluno/:id',
    loadChildren: () =>
      import('./custos/pages/invoice/invoice.module').then(
        (m) => m.InvoicePageModule
      ),
  },
  {
    path: 'faturas/:id',
    loadChildren: () =>
      import('./custos/pages/invoice/invoice.module').then(
        (m) => m.InvoicePageModule
      ),
  },
  {
    path: 'planos/finalizados',
    loadChildren: () =>
      import('./planos/pages/historico-planos/historico-planos.module').then(
        (m) => m.HistoricoPlanosPageModule
      ),
  },
  {
    path: 'admin/alunos/:adminAluno/planos/:adminAluno/finalizados',
    loadChildren: () =>
      import('./planos/pages/historico-planos/historico-planos.module').then(
        (m) => m.HistoricoPlanosPageModule
      ),
  },
  {
    path: 'planos/:id',
    loadChildren: () =>
      import('./planos/pages/detalhe-plano/detalhe-plano.module').then(
        (m) => m.DetalhePlanoPageModule
      ),
  },
  {
    path: 'admin/alunos/:adminAluno/planos/:adminAluno/:id',
    loadChildren: () =>
      import('./planos/pages/detalhe-plano/detalhe-plano.module').then(
        (m) => m.DetalhePlanoPageModule
      ),
  },
  {
    path: 'admin/chamadas/:id',
    loadChildren: () =>
      import('./agenda/pages/chamada/chamada.module').then(
        (m) => m.ChamadaPageModule
      ),
  },
  {
    path: 'admin/chamadas/:id/:alunoUid',
    loadChildren: () =>
      import('./agenda/pages/chamada-wizard/chamada-wizard.module').then(
        (m) => m.ChamadaWizardPageModule
      ),
  },
  {
    path: 'admin/alunos/:adminAluno',
    loadChildren: () =>
      import('./alunos/pages/aluno/aluno.module').then(
        (m) => m.AlunoPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
