import { Injectable } from '@angular/core';
import { BaseEntity } from '../models/base-entity';
import {
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  docData,
  Firestore,
  FirestoreDataConverter,
  getDoc,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  UpdateData,
  updateDoc,
} from '@angular/fire/firestore';
import { from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export abstract class FirestoreBaseService<T extends BaseEntity> {
  protected collection = collection(
    this.firestore,
    this.collectionName()
  ).withConverter<T>(this.converter());

  protected constructor(protected firestore: Firestore) {}

  public findAll() {
    return collectionData(this.collection);
  }

  public findAllWithPagination(
    order: string,
    max: number = 10,
    afterUid?: string
  ) {
    const firestoreQuery = query(this.collection, orderBy(order), limit(max));
    if (afterUid) {
      const docSnap = from(getDoc(doc(this.collection, afterUid)));
      return docSnap.pipe(
        map((d) => query(firestoreQuery, startAfter(d))),
        mergeMap((q) => collectionData(q))
      );
    } else {
      return collectionData(firestoreQuery);
    }
  }

  public findById(uid: string) {
    return docData(doc(this.collection, uid)).pipe(map((t) => (t ? t : null)));
  }

  public create(t: T) {
    if (t.uid) {
      return setDoc(doc(this.collection, t.uid), t);
    } else {
      return addDoc(this.collection, t);
    }
  }

  public updatePartial(uid: string, t: UpdateData<T>) {
    return updateDoc(doc(this.collection, uid), t);
  }

  public update(t: T) {
    return this.updatePartial(t.getUid(), t.toFireStore());
  }

  public save(t: T) {
    return t.uid ? this.update(t) : this.create(t);
  }

  public delete(t: T) {
    return deleteDoc(doc(this.collection, t.uid));
  }

  abstract converter(): FirestoreDataConverter<T>;

  abstract collectionName(): string;
}
