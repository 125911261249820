import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from "./shared/services/authentication.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private auth: AuthenticationService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.getIdTokenResult().pipe(
      map(token => {
        if (route.url.find(u => ['agenda', 'calculadora'].includes(u.path))) {
          return true;
        } else if (token) {
          const isAdmin = token.claims["admin"];
          if (route.url.find(s => s.path.includes('admin'))) {
            return !!isAdmin;
          } else {
            return true;
          }
        } else {
          return false;
        }
      })
    );
  }
}
