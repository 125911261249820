import { Injectable } from '@angular/core';

import { Collection } from '../../shared/models/enums/collections-enum';
import { Aluno } from '../models/aluno';
import { FirestoreBaseService } from '../../shared/services/firestore-base.service';
import {
  DocumentData,
  Firestore,
  FirestoreDataConverter,
  collectionData,
  query,
  where,
} from '@angular/fire/firestore';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { AlunoDoc } from '../models/aluno-doc';
import { Observable, map, mergeMap } from 'rxjs';
import { limit } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class AlunoService extends FirestoreBaseService<Aluno> {
  constructor(
    protected override firestore: Firestore,
    private auth: AuthenticationService
  ) {
    super(firestore);
  }

  override converter(): FirestoreDataConverter<Aluno, DocumentData> {
    return {
      fromFirestore: (snapshot) => {
        const alunoDoc = snapshot.data() as AlunoDoc;
        const { id, ref } = snapshot;
        return new Aluno(alunoDoc, id, ref);
      },
      toFirestore: (aluno: Aluno) => {
        return aluno.toFireStore();
      },
    };
  }

  override collectionName(): string {
    return Collection.ALUNO;
  }

  public getAlunoUser(): Observable<Aluno | null> {
    return this.auth.getUserNotNull().pipe(
      mergeMap((user) => {
        const allAulas = query(
          this.collection,
          where('userUid', '==', user?.uid),
          limit(1)
        );
        return collectionData(allAulas);
      }),
      map((alunos) => (alunos.length > 0 ? alunos[0] : null))
    );
  }

  public findAllClean() {
    const allAulas = query(this.collection, where('nomePesquisa', '!=', null));
    return collectionData(allAulas);
  }

  public alunoSearch(nome: string) {
    const allAulas = query(
      this.collection,
      where('nomePesquisa', '>=', nome.toLowerCase()),
      where('nomePesquisa', '<', nome.toLowerCase() + 'z'),
      where('nomePesquisa', '!=', null)
    );
    return collectionData(allAulas);
  }
}
