import { Injectable } from '@angular/core';
import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  getIdTokenResult,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateEmail,
  updatePassword,
  updateProfile,
  User,
  user,
} from '@angular/fire/auth';
import { GoogleAuthProvider } from 'firebase/auth';
import { Observable, of, throwError } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  redirectUrl = 'menu';

  constructor(private auth: Auth) {}

  public googleLogin() {
    return signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  public emailPasswordLogin(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  public customTokenLogin(token: string) {
    return signInWithCustomToken(this.auth, token);
  }

  public emailVerification() {
    return this.getUser().pipe(
      map((u) => (u ? sendEmailVerification(u) : throwError('No user')))
    );
  }

  public createUser(user: { nome: string; contato: string; email: string }) {}

  public resetPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  public registerUserWithEmail(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  public updatePassword(newPassword: string) {
    return this.getUser().pipe(
      map((u) => (u ? updatePassword(u, newPassword) : throwError('No user')))
    );
  }

  public getUser(): Observable<User | null> {
    return user(this.auth);
  }

  public getUserNotNull(): Observable<User> {
    // @ts-ignore
    return this.getUser().pipe(filter((user) => !!user));
  }

  public getCurrentUser() {
    if (this.auth.currentUser) {
      return this.auth.currentUser;
    } else {
      throw new Error('Usuario ainda não utilizado');
    }
  }

  public updateProfile(nome?: string, sobrenome?: string) {
    return updateProfile(this.getCurrentUser(), {
      displayName: `${nome} ${sobrenome}`,
    });
  }

  public updateEmail(email: string) {
    return updateEmail(this.getCurrentUser(), email);
  }

  public getIdTokenResult() {
    return this.getUser().pipe(
      mergeMap((u) => (u ? getIdTokenResult(u) : of(null)))
    );
  }

  public getIdTokenResultNotNull() {
    return this.getUserNotNull().pipe(mergeMap((u) => getIdTokenResult(u)));
  }

  public getRole() {
    return this.getIdTokenResult().pipe(
      map((token) => token?.claims['role'] as string)
    );
  }

  public isAdmin(): Observable<boolean> {
    return this.getIdTokenResultNotNull().pipe(
      map((token) => !!token?.claims?.['admin'])
    );
  }

  public isAuthenticated() {
    return authState(this.auth).pipe(map((u) => u != null));
  }

  public authState() {
    return authState(this.auth);
  }

  public logout() {
    return signOut(this.auth);
  }
}
