import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  InformacaoService,
  InformacaoUsuario,
} from './login/pages/informacao/services/informacao.service';
import { AuthenticationService } from './shared/services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private auth: AuthenticationService,
    private informacaoService: InformacaoService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.auth.isAuthenticated().subscribe((isAuth) => {
      if (isAuth) {
        this.informacaoService
          .getUserInformation()
          .subscribe((info) =>
            this.handleUserInformationiInformacaoUsuario(info, router)
          );
      }
    });
  }

  private handleUserInformationiInformacaoUsuario(
    info: InformacaoUsuario,
    router: Router
  ): void {
    if (
      !info.nome ||
      info.nome === '' ||
      !info.sobrenome ||
      info.sobrenome === ''
    ) {
      const params = this.route.snapshot.queryParams;
      router.navigate(['/informacoes'], { queryParams: params }).then();
    }
  }
}
